.done__modal {
    width: max-content;
    left: 70vw;
    transform: translateX(-50%);
    position: absolute;
    display: flex;
    top: 1770px;
    height: 90px;
    border-radius: 4px;
    max-width: none;
    color: #edbd51;
    align-items: center;
    justify-content: center;
    border: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media screen and (max-width: 1200px) {
    .done__modal {
        width: max-content;
        left: 50vw;
        transform: translateX(-50%);
        position: absolute;
        display: flex;
        top: 1970px;
        height: 90px;
        border-radius: 4px;
        max-width: none;
        color: #edbd51;
        align-items: center;
        justify-content: center;
        border: 0;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        font-family: Montserrat;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

@media screen and (max-width: 765px) {
    .done__modal {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        display: flex;
        top: 987px;
        height: 80px;
        border-radius: 4px;
        max-width: 246px;
        color: #edbd51;
        align-items: center;
        justify-content: center;
        border: 0;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

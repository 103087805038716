.page6__main__wrap {
    position: relative;
    background: #111010;
    height: 888px;
    padding-top: 100px;
    margin-top: -2px;
}

.page6__card {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 886px;
    flex-shrink: 0;
    border: 1px solid #e7c9a3;
    margin: 0 auto;
    top: 97px;
}

.page6__card__photo {
    width: 794px;
    position: relative;
    top: -174px;
    left: -70px;
    background-image: url(../../assets/page6/548A1680-2-transformed-1.webp);
    background-size: 706px 1057px;
    background-repeat: no-repeat;
    height: 1057px;
    flex-shrink: 0;
    float: left;
}

.page6__head {
    width: 116%;
    position: relative;
    left: 0;
    color: var(--white, #fff);
    font-family: Poiret One;
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    margin-top: 56px;
}

.page6__fuckup {
    position: relative;
    left: 0;
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
    margin-right: 20px;
    padding: 0;
    width: 100vw;
    text-align: left;
    margin-top: 47px;
}

.page6__wrap__card {
    position: relative;
    left: -220px;
    top: 0;
}

.page6__span {
    color: var(--sun_gold, #ffd89d);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
    text-align: left;
}

.page6__text__ul {
    text-align: left;
    width: 96vw;
    position: relative;
    left: 30px;
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
    padding-bottom: 20px;
}

.page6__text__ul li {
    list-style: disc;
    margin-top: 30px;
}

.page6__text__li {
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
}

.page6__br__adapt {
    display: none;
}

@media screen and (max-width: 1377px) {
    .page6__main__wrap {
        position: relative;
        background: #111010;
        height: 945px;
        padding-top: 100px;
        margin-top: -2px;
    }

    .page6__card {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 943px;
        flex-shrink: 0;
        border: 1px solid #e7c9a3;
        margin: 0 auto;
        top: 97px;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
    }

    .page6__card__photo {
        width: 794px;
        position: relative;
        top: -114px;
    }
}

@media screen and (max-width: 1324px) {
    .page6__main__wrap {
        position: relative;
        background: #111010;
        height: 1000px;
        padding-top: 100px;
        margin-top: -2px;
    }

    .page6__card {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 998px;
        flex-shrink: 0;
        border: 1px solid #e7c9a3;
        margin: 0 auto;
        top: 97px;
    }

    .page6__card__photo {
        width: 794px;
        position: relative;
        top: -60px;
    }
}

@media screen and (max-width: 1296px) {
    .page6__main__wrap {
        position: relative;
        background: #111010;
        height: 1100px;
        padding-top: 100px;
        margin-top: -2px;
    }

    .page6__card {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 1098px;
        flex-shrink: 0;
        border: 1px solid #e7c9a3;
        margin: 0 auto;
        top: 97px;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
    }

    .page6__card__photo {
        width: 794px;
        position: relative;
        top: 41px;
    }
}

@media screen and (max-width: 1238px) {
    .page6__main__wrap {
        height: 907px;
    }

    .page6__wrap__card {
        position: absolute;
        left: 20px;
        top: 19px;
    }

    .page6__fuckup {
        width: 85vw;
    }

    .page6__text__ul li {
        width: 77vw;
    }
    .page6__card__photo {
        width: 794px;
        position: relative;
        top: -152px;
    }

    .page6__card {
        height: 906px;
    }
}

@media screen and (max-width: 767px) {
    .page6__main__wrap {
        position: relative;
        background: #111010;
        height: 633px;
        padding-top: 41px;
        width: 100vw;
        margin-top: -1px;
    }

    .page6__card {
        position: relative;
        width: 90%;
        height: 234.896px;
        flex-shrink: 0;
        border: 0.26px solid #e7c9a3;
        margin: 0;
        top: 0;
    }

    .page6__card__photo {
        position: absolute;
        background-image: url(../../assets/page6/548A1680-2-transformed-1.webp);
        background-size: contain;
        width: 206.771px;
        height: 275.26px;
        flex-shrink: 0;
        top: -41px;
        left: -24px;
    }

    .page6__head {
        width: 50vw;
        position: relative;
        left: 130px;
        color: var(--white, #fff);
        font-family: Poiret One;
        font-size: 6vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
        margin-top: 29px;
    }

    .page6__fuckup {
        position: relative;
        left: 130px;
        color: var(--lite-grey, #c5c4c4);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 125.2%;
        margin-right: 0;
        padding: 0;
        width: 52vw;
        text-align: left;
        margin-top: 28px;
    }

    .page6__span {
        color: var(--sun_gold, #ffd89d);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .page6__text__li {
        width: 335px;
        color: var(--lite-grey, #c5c4c4);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 96px;
    }

    .page6__br__adapt {
        display: block;
    }

    .page6__text__ul {
        width: 80vw;
        color: var(--lite-grey, #c5c4c4);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 0 20px;
        padding: 0;
        left: 0;
    }

    .page6__text__ul li {
        list-style: disc;
        margin-top: 20px;
    }

    .page6__wrap__card {
        position: relative;
        left: 0;
        top: 0;
    }

    .page6__br {
        display: none;
    }
}

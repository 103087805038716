.Main {
  text-align: center;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .Main {
    margin: 0;
    padding: 0;
  }
}
.page4__main__wrap {
    background: #111010;
    height: 102em;
    padding: 8.5em 5.18em 0;
}

.page4__head__services {
    width: 490px;
    color: var(--white, #fff);
    font-family: Poiret One;
    font-size: 69px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 56.03px;
    margin-left: 0;
}

.page4__services__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: white;
    text-align: left;
    padding: 0;
}

.page4__grid__line {
    position: relative;
    top: 5px;
    width: 87vw;
    height: 1px;
    background: #565656;
}

.page4__services__grid li:nth-of-type(2n) {
    width: 37vw;
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    margin-top: 40px;
    margin-bottom: 80px;
    text-align: left;
    margin-left: 100px;
}

.page4__services__grid li:nth-of-type(2n + 1) {
    width: 490px;
    margin-right: 100px;
}

.page4__grid__div1 {
    position: absolute;
    display: flex;
    width: 296px;
    height: 162px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #312f2f;
    font-family: Montserrat Bold;
    font-size: 220px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.page4__grid__div2 {
    position: absolute;
    color: var(--lite-gold, #d2b281);
    font-family: Montserrat Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    margin-left: 216px;
    margin-top: 44px;
}

.page4__services__grid:nth-child(2) {
    height: 200px;
    width: 100%;
    margin: 0 auto;
}

.page4__span__desk {
    position: relative;
    top: 12px;
}

.page4__span2__desk {
    position: relative;
    top: 24px;
}

.page4__br__adapt {
    display: none;
}

@media screen and (max-width: 1500px) {
    .page4__main__wrap {
        height: 114em;
    }
}

@media screen and (max-width: 1250px) {
    .page4__services__grid li:nth-of-type(2n) {
        margin-left: 5vw;
    }

    .page4__services__grid li:nth-of-type(2n + 1) {
        margin-right: 5vw;
        width: 40vw;
    }

    .page4__main__wrap {
        height: 122em;
    }

    .page4__grid__div2 {
        position: absolute;
        display: flex;
        width: 12em;
    }
}

@media screen and (max-width: 1100px) {
    .page4__main__wrap {
        height: 125em;
    }
}

@media screen and (max-width: 1050px) {
    .page4__services__grid li:nth-of-type(2n + 1) {
        margin-right: 30px;
        margin-left: 30px;
        width: 40vw;
    }

    .page4__main__wrap {
        width: 100vw;
        padding: 62px 0 0 0px;
        height: 133em;
    }

    .page4__services__grid:nth-child(2) {
        height: 200px;
        width: max-content;
        margin: 0 auto;
    }

    .page4__grid__div2 {
        margin-left: 0;
        margin-top: 165px;
    }

    .page4__grid__line {
        width: 85vw;
    }

    .page4__head__services {
        margin-left: 6vw;
    }
}

@media screen and (max-width: 966px) {
    .page4__main__wrap {
        height: 143em;
    }
}

@media screen and (max-width: 890px) {
    .page4__main__wrap {
        height: 146em;
    }
}

@media screen and (max-width: 880px) {
    .page4__main__wrap {
        height: 157em;
    }
}

@media screen and (max-width: 835px) {
    .page4__main__wrap {
        height: 167em;
    }
}

@media screen and (max-width: 790px) {
    .page4__main__wrap {
        height: 171em;
    }
}

@media screen and (max-width: 767px) {
    .page4__main__wrap {
        position: relative;
        background: #111010;
        width: 100vw;
        height: 805px;
        padding: 62px 0 0 0px;
        margin-top: -2px;
    }

    .page4__services__grid li:nth-of-type(2n + 1) {
        margin-right: 30px;
        margin-left: 0;
        width: max-content;
    }

    .page4__br__adapt {
        display: block;
    }

    .page5__head__flex {
        display: flex;
    }

    .page4__services__grid:nth-child(2) {
        margin-top: 25px;
        margin-left: 20px;
        width: auto;
    }

    .page4__head__services {
        width: 215px;
        color: var(--white, #fff);
        font-family: Poiret One;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
        margin: 0 0 0 20px;
        padding: 0;
    }

    .page4__grid__div1 {
        position: relative;
        display: flex;
        float: left;
        width: 72px;
        height: 63.083px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: var(--grey, #312f2f);
        font-family: Montserrat;
        font-size: 57.292px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-top: -6px;
        font-family: Montserrat Bold;
    }

    .page4__grid__div2 {
        display: flex;
        align-items: center;
        width: max-content;
        right: 16.5px;
        height: 27px;
        position: relative;
        color: var(--lite-gold, #d2b281);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 108%;
        text-transform: uppercase;
        text-align: left;
        margin-top: 12px;
    }

    .page4__services__grid {
        display: block;
        color: white;
        text-align: left;
        padding: 0;
    }

    .page4__services__grid li:nth-of-type(2n) {
        display: block;
        width: 95%;
        color: var(--lite-grey, #c5c4c4);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        margin-top: 23px;
        margin-bottom: 50px;
        margin-left: 0;
    }

    .page4__grid__line {
        width: 90vw;
        height: 0.5px;
        background: #565656;
    }

    .page4__br {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .page4__main__wrap {
        height: 850px;
    }
}

@media screen and (max-width: 550px) {
    .page4__main__wrap {
        height: 890px;
    }
}

@media screen and (max-width: 460px) {
    .page4__main__wrap {
        height: 920px;
    }
}

@media screen and (max-width: 410px) {
    .page4__main__wrap {
        height: 960px;
    }
}

@media screen and (max-width: 400px) {
    .page4__main__wrap {
        height: 975px;
    }
}

@media screen and (max-width: 365px) {
    .page4__main__wrap {
        height: 1020px;
    }
}

@media screen and (max-width: 330px) {
    .page4__main__wrap {
        height: 1050px;
    }
}

.page8__main__wrap {
    background-image: url(../../assets/page8/metaphor_11zon.webp);
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70px;
    margin-top: -1px;
    height: 36vw;
}

.page8__card {
    border: 1px solid #fff;
    flex-shrink: 0;
    height: 22em;
    margin-left: 52vw;
    position: relative;
    width: 35em;
}

.page8__img__wrap {
    top: -20%;
    margin-left: 31%;
    position: relative;
    width: 200px;
    height: 130px;
    background-color: rgba(0, 0, 0, 100);
}

.page8__img {
    position: absolute;
    width: 112.078px;
    height: 130px;
    flex-shrink: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
    left: 23%;
}

.page8__card__text {
    position: relative;
    top: 10%;
    transform: translateY(-50%);
    color: var(--sun_gold, #ffd89d);
    text-align: center;
    font-family: Poiret One;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 116%;
}

.page8__card__bottom {
    position: relative;
    right: 10px;
    bottom: 10px;
    width: 98%;
    color: var(--lite-grey, #c5c4c4);
    font-family: 'Miamanueva';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
    text-align: right;
}

.page8__є {
    transform: rotate(0deg);
    font-family: Liana;
    display: inline-block;
    position: relative;
    font-style: italic;
    font-size: 22px;
    top: 2px;
    left: -42px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

#span__c__page8 {
    font-size: 21px;
    position: relative;
    left: -2px;
    top: 1px;
    font-family: "Miamanueva";
}

@media screen and (max-width: 1200px) {
    .page8__card {
        border: 1px solid #fff;
        flex-shrink: 0;
        height: 22em;
        margin: 0 auto;
        position: relative;
        width: 35em;
    }
}

@media screen and (max-width: 1100px) {
    .page8__main__wrap {
        height: 45vw;
    }
}

@media screen and (max-width: 900px) {
    .page8__main__wrap {
        height: 50vw;
    }
}

@media screen and (max-width: 767px) {
    .page8__main__wrap {
        background-image: url(../../assets/page8/metaphor_11zon.webp);
        background-position: center;
        width: 100vw;
        height: 36vw;
        flex-shrink: 0;
        margin: -1px 0 0 0;
        padding: 17px 0 0px 0;
    }

    .page8__є {
        transform: rotate(0deg);
        display: inline-block;
        position: relative;
        font-style: italic;
        padding-bottom: -26px;
        font-family: "Poiret One";
        font-size: 6px;
        -webkit-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        top: -12.5px;
        left: -10px;
    }

    .page8__card__text {
        display: flex;
        width: 157.85px;
        height: 56.804px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: var(--lite-grey, #ffe49a);
        text-align: center;
        font-family: Poiret One;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 116%;
        top: -10%;
    }

    .page8__card {
        position: relative;
        margin-left: 53vw;
        width: 156.619px;
        height: 94.584px;
        flex-shrink: 0;
        border: 0.26px solid #fff;
    }

    .page8__card__bottom {
        position: absolute;
        display: flex;
        top: 77px;
        left: 73px;
        width: max-content;
        height: 15px;
        justify-content: center;
        flex-shrink: 0;
        color: var(--lite-grey, #c5c4c4);
        font-family: 'Miamanueva';
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.323px;
        margin: 0 0px 0px -7px;
    }

    #span__c__page8 {
        font-family: "Miamanueva";
        position: relative;
        display: flex;
        align-items: center;
        font-size: 6px;
        width: 4px;
        height: 100%;
        left: -1px;
    }

    #span__inside {
        position: absolute;
        height: max-content;
        transform: translateY(-50%);
        top: 50%;
    }

    .page8__img {
        position: absolute;
        width: 29.187px;
        height: 34.833px;
        flex-shrink: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
        left: 23%;
    }

    .page8__img__wrap {
        top: -18%;
        margin-left: 30%;
        position: relative;
        width: 56px;
        height: 55px;
        background-color: rgba(0, 0, 0, 100);
    }
}

.page7__main__wrap {
    position: relative;
    background-image: url(../../assets/page7/white_building.webp);
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: max-content;
    padding-top: 202px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: -2px;
}

.page7__head {
    color: var(--white, #fff);
    font-family: Poiret One;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-left: 0;
}

.page7__head__span {
    color: var(--sun_gold, #ffd89d);
    font-family: Poiret One;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.page7__ul__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-auto-rows: max-content;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 0;
    margin-top: 150px;
    margin-bottom: 0;
    padding-bottom: 0;
    position: relative;
}

.page7__ul__grid::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    top: 17px;
    left: 50%;
    width: 1px; 
    background-color: #ffd89d;
    transform: translateX(-50%);
}

.page7__li__year {
    color: var(--sun_gold, #ffd89d);
    font-family: Liana;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
}

.page7__li__text {
    width: 43vw;
    height: max-content;
    text-align: left;
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0;
}

.page7__li__textright {
    width: 43vw;
    text-align: left;
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 22px;
}

.page7__li__textright:nth-of-type(1) {
    margin-top: -58px;
}

.page7__li__ul {
    padding-left: 0;
}

.page7__li__ul li:nth-of-type(1) {
    margin-top: -80px;
}

.page7__text__ul,
.page7__li__year {
    margin-bottom: 160px;
    padding: 0;
}

#page7__li5__adapt {
    margin-top: -63px;
}

.page7__img__disk {
    margin: 5px 36px;
    width: 20px;
    height: 19.799px;
    flex-shrink: 0;
}

.page7__vertical__line {
    position: absolute;
    height: 74em;
    top: 37em;
    left: 49.3vw;
    transform: rotate(0deg);
    width: 1px;
    background: var(--sun_gold, #ffd89d);
    -webkit-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

.page7__disk__2023 {
    white-space: nowrap;
    margin-left: 0;
    position: relative;
    top: -30px;
    left: 100%;
    transform: translateX(-83%);
    width: max-content;
    text-align: right;
    color: var(--sun_gold, #ffd89d);
    font-family: Liana;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
    -webkit-transform: translateX(-83%);
    -moz-transform: translateX(-83%);
    -ms-transform: translateX(-83%);
    -o-transform: translateX(-83%);
}

.page7__disk__2022 {
    white-space: nowrap;
    width: max-content;
    text-align: left;
    position: relative;
    top: -30px;
    -webkit-transform: translateX(-16%);
    -moz-transform: translateX(36%);
    -ms-transform: translateX(36%);
    -o-transform: translateX(36%);
    color: var(--sun_gold, #ffd89d);
    font-family: Liana;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
}

.page7__disk__2020 {
    white-space: nowrap;
    margin-left: 0;
    position: relative;
    top: -30px;
    left: 100%;
    transform: translateX(-83.7%);
    width: max-content;
    text-align: right;
    color: var(--sun_gold, #ffd89d);
    font-family: Liana;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
    -webkit-transform: translateX(-83.7%);
    -moz-transform: translateX(-83.7%);
    -ms-transform: translateX(-83.7%);
    -o-transform: translateX(-83.7%);
}

.page7__disk__2019 {
    white-space: nowrap;
    margin-left: 0;
    position: relative;
    top: -30px;
    transform: translateX(-83.7%);
    width: max-content;
    text-align: right;
    color: var(--sun_gold, #ffd89d);
    font-family: Liana;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
    -webkit-transform: translateX(-17.5%);
}

#page7__li5,
#page7__li4,
#page7__li3 {
    height: 250px;
}

#page7__li6 {
    margin-top: 22px;
}

#page7__li7 {
    height: 100px;
    margin-bottom: 0;
    margin-top: 170px;
}

.page7__adapt__height {
    height: 17em;
}

.page7__vertical__line__adapt,
.page7__br__adapt {
    display: none;
}

.p7__ul__left,
.p7__ul__right {
    display: flex;
    width: 40vw;
    height: max-content;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: left;
    color: var(--lite-grey, #c5c4c4);
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
}

.p7__ul__right li {
    position: relative;
    top: 0;
}

.p7__ul__left li {
    position: relative;
    top: 0;
}

.p7__ul__right2 li {
    position: relative;
    top: 0;
    display: flex;
    width: 40vw;
    height: max-content;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: left;
    color: var(--lite-grey, #c5c4c4);
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
}

.p7__ul__left2 {
    height: max-content;
}

.p7__ul__left2 li {
    position: relative;
    top: 0px;
    display: flex;
    width: 40vw;
    height: max-content;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: left;
    color: var(--lite-grey, #c5c4c4);
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
}

.page7__ul__grid > li:nth-last-child(2),
.page7__ul__grid > li:nth-last-child(1) {
    height: 15px;
    margin-bottom: 0;
}

.page7__ul__grid > li {
    margin-bottom: 100px;
}

@media screen and (max-width: 1321px) {
    .p7__ul__left,
    .p7__ul__right {
        width: 39vw;
    }

    .page7__disk__2019 {
        transform: translateX(-28.2%);
        -webkit-transform: translateX(-28.2%);
        -moz-transform: translateX(-28.2%);
        -ms-transform: translateX(-28.2%);
        -o-transform: translateX(-28.2%);
}
    .page7__disk__2020 {
        transform: translateX(-97.1%);
        -webkit-transform: translateX(-97.1%);
        -moz-transform: translateX(-97.1%);
        -ms-transform: translateX(-97.1%);
        -o-transform: translateX(-97.1%);
}
    .page7__disk__2022 {
        transform: translateX(-25.5%);
        -webkit-transform: translateX(-25.5%);
        -moz-transform: translateX(-25.5%);
        -ms-transform: translateX(-25.5%);
        -o-transform: translateX(-25.5%);
}
    .page7__disk__2023 {
        transform: translateX(-93.5%);
        -webkit-transform: translateX(-93.5%);
        -moz-transform: translateX(-93.5%);
        -ms-transform: translateX(-93.5%);
        -o-transform: translateX(-93.5%);
}
}

@media screen and (max-width: 1240px) {
    .page7__disk__2019 {
        transform: translateX(-25.5%);
        -webkit-transform: translateX(-25.5%);
        -moz-transform: translateX(-25.5%);
        -ms-transform: translateX(-25.5%);
        -o-transform: translateX(-25.5%);
}
}

@media screen and (max-width: 1170px) {
    .page7__disk__2019 {
        transform: translateX(-26.1%);
        -webkit-transform: translateX(-26.1%);
        -moz-transform: translateX(-26.1%);
        -ms-transform: translateX(-26.1%);
        -o-transform: translateX(-26.1%);
    }
    .page7__disk__2020 {
        transform: translateX(-96%);
        -webkit-transform: translateX(-96%);
        -moz-transform: translateX(-96%);
        -ms-transform: translateX(-96%);
        -o-transform: translateX(-96%);
    }
    .page7__disk__2022 {
        transform: translateX(-24%);
        -webkit-transform: translateX(-24%);
        -moz-transform: translateX(-24%);
        -ms-transform: translateX(-24%);
        -o-transform: translateX(-24%);
    }
    .page7__disk__2023 {
        transform: translateX(-92%);
        -webkit-transform: translateX(-92%);
        -moz-transform: translateX(-92%);
        -ms-transform: translateX(-92%);
        -o-transform: translateX(-92%);
    }
}

@media screen and (max-width: 3200px) {
    .page7__vertical__line {
        height: 75em;
        top: 42.5em;
        left: 48.8vw;
    }
}

@media screen and (max-width: 3000px) {
    .page7__vertical__line {
        height: 75em;
        top: 42em;
        left: 48.8vw;
    }
}

@media screen and (max-width: 2850px) {
    .page7__vertical__line {
        height: 75em;
        top: 41em;
        left: 48.9vw;
    }
}

@media screen and (max-width: 2723px) {
    .page7__vertical__line {
        height: 75.5em;
        top: 40em;
    }
}

@media screen and (max-width: 2670px) {
    .page7__vertical__line {
        left: 48.9vw;
        height: 75em;
    }
}

@media screen and (max-width: 2588px) {
    .page7__vertical__line {
        top: 38.5em;
        height: 77em;
        left: 49vw;
    }
}

@media screen and (max-width: 2402px) {
    .page7__vertical__line {
        top: 37em;
        height: 77em;
        left: 49.1vw;
    }
}

@media screen and (max-width: 2300px) {
    .page7__vertical__line {
        height: 77.1em;
        top: 36.5em;
        left: 49.1vw;
    }
}

@media screen and (max-width: 2200px) {
    .page7__vertical__line {
        top: 36em;
        left: 49.27vw;
        height: 76.7em;
    }
}

@media screen and (max-width: 2100px) {
    .page7__vertical__line {
        top: 35em;
        height: 77.5em;
    }
}

@media screen and (max-width: 2005px) {
    .page7__vertical__line {
        top: 34.3em;
        left: 49.4vw;
        height: 77em;
    }
}

@media screen and (max-width: 1900px) {
    .page7__vertical__line {
        top: 34.3em;
        left: 49.5vw;
        height: 76em;
    }
}

@media screen and (max-width: 1860px) {
    .page7__vertical__line {
        height: 77.4em;
        top: 33.3em;
        left: 49.5vw;
    }
}

@media screen and (max-width: 1800px) {
    .page7__vertical__line {
        height: 77.2em;
    }
}

@media screen and (max-width: 1793px) {
    .page7__vertical__line {
        height: 78.5em;
    }
}

@media screen and (max-width: 1730px) {
    .page7__vertical__line {
        height: 79.4em;
        top: 32em;
        left: 49.6vw;
    }
}

@media screen and (max-width: 1670px) {
    .page7__vertical__line {
        height: 78.5em;
    }
}

@media screen and (max-width: 1600px) {
    .page7__vertical__line {
        height: 79em;
        top: 32em;
        left: 49.8vw;
    }
    .page7__img__disk {
        margin: 0 auto;
        width: 87px;
        height: 19.799px;
        flex-shrink: 0;
    }
}

@media screen and (max-width: 1560px) {
    .page7__vertical__line {
        height: 78em;
        top: 31.5em;
    }
}

@media screen and (max-width: 1507px) {
    .page7__vertical__line {
        height: 80.5em;
        top: 31em;
        left: 49.9vw;
    }
}

@media screen and (max-width: 1415px) {
    .page7__vertical__line {
        height: 81 em;
        top: 30em;
        left: 50.1vw;
    }
}

@media screen and (max-width: 1321px) {

    .page7__vertical__line {
        top: 30em;
        height: 84em;
        left: 50.2vw;
    }

    .page7__disk__2020 {
        width: 47.5vw;
    }
}

@media screen and (max-width: 1290px) {
    .page7__vertical__line {
        height: 85.5em;
        left: 50.4vw;
        top: 29em;
    }
}

@media screen and (max-width: 1220px) {
    .page7__vertical__line {
        height: 84em;
        top: 29em;
        left: 50.4vw;
    }
}

@media screen and (max-width: 1210px) {
    .page7__vertical__line {
        height: 86em;
    }
}

@media screen and (max-width: 1170px) {
    .page7__vertical__line {
        top: 28em;
        left: 50.6vw;
    }
}

@media screen and (max-width: 1158px) {
    .page7__vertical__line {
        height: 88em;
        left: 50.8vw;
    }
}

@media screen and (max-width: 1084px) {
    .page7__vertical__line {
        top: 28em;
        left: 50.8vw;
    }
}

@media screen and (max-width: 1050px) {
    .page7__text__ul,
    .page7__li__year {
        margin-bottom: 115px;
        margin-left: 15px;
    }

    .page7__ul__grid,
    .page7__vertical__line,
    .page7__lable__year {
        display: none;
    }

    .page7__lable__year2 {
        display: block;
    }

    .page7__main__wrap {
        position: relative;
        background-image: url(../../assets/page7/adapt/vertical.png);
        justify-content: center;
        background-repeat: no-repeat;
        width: 100vw;
        height: max-content;
        flex-shrink: 0;
        padding-top: 150px;
        padding-left: 0;
        padding-right: 0;
        margin-top: -1px;
        padding-top: 56px;
        padding-bottom: 1px;
    }

    .page7__br {
        display: none;
    }

    .page7__br__adapt {
        display: block;
    }

    .page7__li__ul {
        margin-left: -25px;
    }

    .page7__head__span {
        display: contents;
        color: var(--sun_gold, #ffd89d);
        font-family: Poiret One;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .page7__vertical__line__adapt {
        display: block;
        position: relative;
        left: 78px;
        top: 24px;
        transform: rotate(0deg);
        width: 0.26px;
        height: max-content;
        background: var(--sun_gold, #ffd89d);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        color: var(--lite-grey, #c5c4c4);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    #page7__adapt__lastul,
    #page7__adapt__lastspan,
    #page7__adapt__lastli {
        height: 0;
    }

    #page7__adapt__lastul {
        margin: 0;
        padding: 0;
    }

    #page7__adapt__lastli {
        margin-bottom: 0;
    }

    .page7__adapt__lastlabel {
        position: relative;
        top: 0px !important;
        height: 0;
    }

    .page7__adapt__lastimg {
        position: relative;
        top: 10px !important;
    }

    .page7__adapt__ul {
        margin-bottom: 20px;
    }

    .page7__adapt__height {
        display: block;
        height: 8em;
    }

    .page7__vertical__line__adapt ul {
        position: relative;
        top: -10px;
        display: flex;
        width: 75vw;
        flex-direction: column;
        flex-shrink: 0;
        padding: 0;
    }

    .page7__adpt__img label {
        width: 40px;
        position: relative;
        top: -1px;
    }

    .page7__adpt__img img {
        position: relative;
        width: 7px;
        margin-left: 12px;
        top: -1px;
    }

    .page7__li__text {
        position: relative;
        margin-top: -8px;
        display: flex;
        width: 250px;
        height: 0px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: var(--lite-grey, #c5c4c4);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .page7__li__textright {
        margin-top: 0;
        display: flex;
        width: 250px;
        height: 0px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: var(--lite-grey, #c5c4c4);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .page7__li__year {
        margin-bottom: 36px;
    }

    #page7__li5,
    #page7__li4,
    #page7__li3 {
        height: 86px;
    }

    .page7__main__wrap li {
        margin-left: 30px;
        margin-top: 2px;
        margin-bottom: 10px;
        text-align: left;
        list-style: disc;
    }

    .page7__li3 {
        margin-left: 36px;
    }

    .page7__br {
        display: none;
    }

    #page7__li6 {
        margin-left: 85px;
        margin-top: 8px;
    }

    #page7__li3 ul li {
        margin-left: 75px;
    }

    #page7__li3 ul li:nth-of-type(1) {
        margin-top: -100px;
        width: 65vw;
    }

    #page7__li3 ul li:nth-of-type(2) {
        margin-top: 15px;
        width: 65vw;
    }

    #page7__li3 ul li:nth-of-type(3) {
        margin-top: -10px;
        width: 65vw;
    }

    #page7__li5 ul {
        margin-top: -50px;
    }

    #page7__li5 ul li {
        margin-top: 40px;
    }

    #page7__li5 ul li:nth-of-type(2) {
        margin-top: 22px;
    }

    .page7__br__adapt {
        display: block;
    }

    .page7__img__disk {
        position: relative;
        left: -1px;
        margin: 0 16px;
        height: 19.799px;
        flex-shrink: 0;
        width: 5.208px;
        height: 5.323px;
        flex-shrink: 0;
    }

    .page7__disk {
        display: block;
    }

    #page7__li__adapt li:nth-of-type(2) {
        margin-top: 3px;
        width: 65vw;
    }

    #page7__li__adapt li:nth-of-type(3) {
        margin-top: 8px;
        width: 65vw;
    }

    #page7__li__adapt li:nth-of-type(4) {
        margin-top: 0;
        width: 65vw;
        height: max-content;
    }

    .page7__li__ul li:nth-of-type(1) {
        margin-top: -50px;
    }

    #page7__li6,
    #page7__li7 {
        height: 100px;
        margin-bottom: 0;
        margin-top: 8px;
        width: 65vw;
    }

    .page7__span__year {
        height: 22px;
    }

    #page7__li4,
    .page7__li__year,
    .page7__disk__2023,
    .page7__disk__2022,
    .page7__disk__2020,
    .page7__disk__2019 {
        display: none;
    }

    #page7__li5,
    #page7__li4,
    #page7__li3 {
        height: max-content;
        margin-top: 0;
    }

    #page7__li5 ul li {
        position: relative;
        margin-top: 65px;
        height: max-content;
    }

    #page7__li5 ul li:nth-of-type(2) {
        margin-top: 0;
        width: 90%;
    }

    #page7__li6,
    #page7__li7 {
        position: absolute;
        margin-top: 16px;
        height: 1px;
    }

    #page7__li5 ul {
        margin-bottom: 0;
        width: 80%;
    }

    .page7__li__textright:nth-of-type(1) {
        position: relative;
        height: max-content;
    }

    #page7__li__adapt li:nth-of-type(2) {
        height: max-content;
        margin-top: 0;
    }

    #page7__li__adapt li:nth-of-type(3) {
        height: max-content;
        margin-top: 0;
    }

    #page7__li3 ul li:nth-of-type(2) {
        margin-top: 0;
    }

    #page7__li3 ul li:nth-of-type(3) {
        margin-top: 0;
    }

    .page7__ul__grid__adapt {
        display: block;
        position: relative;
        padding: 0;
        left: -78px;
        top: -11px;
        margin-top: 93px;
        height: 828px;
        margin-bottom: 0;
        height: max-content;
        width: max-content;
        margin-left: 20px;
        margin-bottom: 90px;
    }

    .page7__li__textright {
        width: 70vw;
    }

    .page7__head {
        position: relative;
        top: 0px;
        display: flex;
        width: 90vw;
        height: 44.394px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: var(--white, #fff);
        font-family: Poiret One;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 20px;
    }

    .page7__disk__2023adapt {
        position: absolute;
        left: -50px;
        top: 2px;
        display: block;
        margin-left: -15px;
        display: flex;
        width: 39.583px;
        height: 17.566px;
        flex-direction: row;
        justify-content: center;
        flex-shrink: 0;
        color: var(--sun, #ffd89d);
        font-family: Liana;
        font-size: 12.5px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: lowercase;
        align-items: center;
    }

    .page7__adpt__img {
        position: absolute;
        left: -55px;
        top: 0;
        display: flex;
        width: 59px;
        flex-direction: row;
        justify-content: center;
        flex-shrink: 0;
        color: var(--sun, #ffd89d);
        font-family: Liana;
        font-size: 12.5px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: lowercase;
        align-items: center;
    }

    .page7__disk__2022adapt {
        position: absolute;
        left: -50px;
        top: 2px;
        display: block;
        margin-left: -16px;
        display: flex;
        width: 39.583px;
        height: 17.566px;
        flex-direction: row;
        justify-content: center;
        flex-shrink: 0;
        color: var(--sun, #ffd89d);
        font-family: Liana;
        font-size: 12.5px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: lowercase;
        align-items: center;
    }

    .page7__disk__2020adapt {
        position: absolute;
        left: -50px;
        top: 2px;
        display: block;
        margin-left: -16px;
        display: flex;
        width: 39.583px;
        height: 17.566px;
        flex-direction: row;
        justify-content: center;
        flex-shrink: 0;
        color: var(--sun, #ffd89d);
        font-family: Liana;
        font-size: 12.5px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: lowercase;
        align-items: center;
    }

    .page7__disk__2019adapt {
        position: absolute;
        left: -50px;
        top: -14px;
        display: block;
        margin-left: -13px;
        display: flex;
        width: 39.583px;
        height: 17.566px;
        flex-direction: row;
        justify-content: center;
        flex-shrink: 0;
        color: var(--sun, #ffd89d);
        font-family: Liana;
        font-size: 12.5px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: lowercase;
        align-items: center;
    }

    .page7__li__text {
        height: max-content;
    }
}

@media screen and (max-width: 444px) {
    #page7__li5 ul li:nth-of-type(2) {
        margin-top: 0;
        width: 70%;
    }
}

@media screen and (max-width: 373px) {
    .page7__li__textright {
        width: 70vw;
    }
    #page7__li5 ul li:nth-of-type(2) {
        margin-top: 0;
        width: 62%;
    }
}

@media screen and (max-width: 362px) {
    #page7__li6,
    #page7__li7 {
        position: absolute;
        margin-top: 23px;
        height: 1px;
    }

    .page7__disk__2019adapt {
        top: -21px;
    }
}

@media screen and (max-width: 355px) {
    #page7__li__adapt li:nth-of-type(4) {
        margin-top: 20px;
    }

    #page7__li3 ul li:nth-of-type(1) {
        margin-top: -80px;
    }
}

@media screen and (max-width: 340px) {
    #page7__li5 ul li:nth-of-type(2) {
        margin-top: 0;
        width: 55%;
    }
}

@media screen and (max-width: 277px) {
    #page7__li6,
    #page7__li7 {
        position: absolute;
        margin-top: 29px;
        height: 1px;
    }

    .page7__disk__2019adapt {
        top: -28px;
    }
}

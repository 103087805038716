html {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
}

.start__page__wrap {
    background-image: url(../../assets/page1/foto_chess_1_11zon.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 56.8em;
    padding: 1.25em 1.25em 0 1.25em;
}

.start__page__head ul {
    justify-content: space-between;
    margin: 0;
    display: flex;
    padding-right: 40px;
}

li {
    list-style: none;
}

.logo-img {
    position: relative;
    background-image: url(../../assets/page1/logo1.svg);
    background-position: center;
    background-size: 11em;
    width: 7.875em;
    height: 4.889em;
    flex-shrink: 0;
}

.text-logo {
    position: absolute;
    top: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white, #fff);
    background: rgba(30, 30, 30, 0.8);
    width: max-content;
    height: 2.5em;
    flex-shrink: 0;
    text-align: center;
    font-family: Montserrat;
    font-size: 0.5em;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 2.08px;
}

.start-head-center {
    display: flex;
    justify-content: space-between;
}

.start-head-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.25em 1.1em;
    border: 0;
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: none;
    cursor: pointer;
}

.start-head-link:hover {
    color: var(--white, #fff);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.start-head-link:active {
    color: var(--white, #fff);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.start-link-profile1,
.start-link-profile2 {
    border: 0;
    background: none;
    cursor: pointer;
}

.start-body-left {
    position: relative;
    z-index: 1;
    margin: 0;
}

.start-ul-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.start-head-right {
    margin: 1.25em;
}

.start-link-profile1 {
    background-image: url(../../assets/page1/fb.svg);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    margin: 0 12px;
}

.start-link-profile1:hover {
    background-image: url(../../assets/page1/fbhover.svg);
}

.start-link-profile1:active {
    background-image: url(../../assets/page1/fbactive.svg);
}

.start-link-profile2 {
    background-image: url(../../assets/page1/inst.svg);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.start-link-profile2:hover {
    background-image: url(../../assets/page1/insthover.svg);
}

.start-link-profile2:active {
    background-image: url(../../assets/page1/instactive.svg);
}

.start__page__body {
    display: flex;
    margin-top: 187px;
    height: 633px;
    width: 98vw;
}

#body-first-li {
    color: var(--white, #fff);
    font-family: Poiret One;
    font-size: 118px;
    font-style: normal;
    font-weight: 400;
    line-height: 105px;
    text-transform: uppercase;
    margin-left: 20px;
    text-align: left;
}

.natali-logo {
    width: 776px;
    flex-shrink: 0;
    position: absolute;
    top: -212px;
    right: 0;
}

#body-second-li {
    position: relative;
    top: -20px;
    color: var(--sun, #ffe49a);
    font-family: "Liana", sans-serif;
    font-size: 3.5em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
    text-align: right;
}

#body-third-li {
    position: relative;
    top: -20px;
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
    margin: 1.4em 1em;
    text-align: left;
}

.start__body__button {
    display: flex;
    width: 11.3em;
    height: 3.2em;
    padding: 1em 4em;
    justify-content: center;
    align-items: center;
    gap: 0.6em;
    flex-shrink: 0;
    background: var(--sun_gold, #ffd89d);
    border: 0;
    color: #312f2f;
    box-shadow: 0px 4px 30px 0px #ffe49a;
    text-align: center;
    font-family: Montserrat Medium;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    margin-left: 20px;
}

.start__body__button:hover {
    color: var(--white, #fff);
    text-align: center;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 11.3em;
    height: 3.2em;
    padding: 14px 64px;
    gap: 10px;
    flex-shrink: 0;
    background: var(--gold, #edbd51);
}

.start__body__button:active {
    color: var(--white, #fff);
    text-align: center;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: var(--lite-gold, #d2b281);
    box-shadow: 0px 4px 30px 0px #ffe49a;
}

.start-body-right {
    position: relative;
    width: max-content;
    left: 50vw;
}

.shrift__c {
    transform: rotate(0deg);
    display: inline-block;
    position: relative;
    font-family: "Liana";
    font-style: oblique;
    font-size: 54px;
    top: 0px;
    left: 0;
}

.shrift__є {
    display: inline-block;
    position: relative;
    font-family: auto;
    font-style: oblique;
    font-size: 13px;
    font-weight: 600;
    top: 0px;
    left: -19px;
}

.natali-logo__adapt,
.start__burger__close,
.start__burger__menu,
.start__burger__button,
.burger-menu {
    display: none;
}

@media screen and (max-width: 1050px) {
    .start-body-right {
        position: absolute;
        z-index: 0;
    }

    #span2__page1 {
        display: inline-block;
        position: relative;
        font-family: auto;
        font-style: oblique;
        font-size: 14px;
        font-weight: 600;
        top: 0px;
        left: -19px;
    }

    .natali-logo {
        width: 776px;
        flex-shrink: 0;
        position: absolute;
        left: 50%;
        top: -204px;
        right: 0;
        transform: translateX(-50%);
    }

    .start__page__head ul {
        padding-right: 0;
        align-items: center;
    }

    .start-body-left {
        position: absolute;
        margin: 0;
        z-index: 1;
    }

    .start-head-right {
        display: flex;
    }

    .start__page__body {
        display: flex;
        margin-top: 187px;
        height: 633px;
        width: min-content;
        text-align: -webkit-center;
    }

    .start-head-link {
        font-size: 18px;
    }

    .start-head-link:hover {
        font-size: 18px;
    }

    .start-head-link:active {
        font-size: 18px;
    }

    .start__body__button {
        margin-left: 0;
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {
    .start__page__wrap {
        background-image: url(../../assets/page1/adapt/chess.webp);
        background-repeat: no-repeat;
        height: max-content;
        flex-shrink: 0;
        text-align: center;
        padding: 0;
        width: 100vw;
    }

    .shrift__c {
        transform: rotate(0deg);
        display: inline-block;
        position: relative;
        font-family: "Liana";
        font-style: oblique;
        font-size: 24px;
        top: 0px;
        left: 0px;
        width: 9px;
    }

    #span2__page1 {
        display: inline-block;
        position: relative;
        font-family: auto;
        font-style: oblique;
        font-size: 8px;
        font-weight: 600;
        top: 1px;
        left: -9px;
    }

    .logo-img {
        position: relative;
        background-image: url(../../assets/page1/logo1.svg);
        background-position: center;
        width: 125px;
        flex-shrink: 0;
        background-size: cover;
    }

    .shrift__є {
        transform: rotate(0);
        display: inline-block;
        position: relative;
        top: 0px;
        left: -9px;
        font-size: 6px;
        font-weight: 600;
    }

    .start__page__head ul {
        display: inline-flex;
        align-items: center;
        padding: 0;
        margin: 20px 10px;
    }

    .start__page__head {
        width: min-content;
    }

    .burger-menu {
        display: block;
        width: 30px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bar {
        width: 30px;
        height: 3px;
        background-color: lightgray;
        margin: 6px 0;
    }

    .natali-logo,
    .start-link-profile1,
    .start-link-profile2,
    .start-head-center,
    .head-center-li {
        display: none;
    }

    #body-first-li {
        margin-top: 130px;
        color: var(--white, #fff);
        width: max-content;
        font-family: Poiret One;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
        text-transform: uppercase;
        margin-left: 0;
    }

    #body-second-li {
        position: relative;
        color: var(--sun, #ffe49a);
        width: max-content;
        font-family: Liana;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: lowercase;
        white-space: nowrap;
        margin-left: 96px;
        top: -7px;
    }

    #body-third-li {
        display: flex;
        width: max-content;
        height: 95px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: #dbd9d9;
        text-align: left;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125.2%;
        margin-left: 0;
        margin: 10px 0;
    }

    .start__body__button {
        display: flex;
        position: relative;
        top: -20px;
        width: 246px;
        height: 69px;
        padding: 14px 64px;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 10px;
        flex-shrink: 0;
        flex-wrap: wrap;
        background: var(--sun_gold, #ffd89d);
        border: 0;
        border-radius: 4px;
        color: var(--grey, #312f2f);
        text-align: center;
        font-family: Montserrat Medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        margin-left: 0;
        margin: 0 auto;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }

    .start__body__button:hover {
        display: flex;
        width: 246px;
        height: 69px;
        padding: 14px 64px;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 10px;
        flex-shrink: 0;
        flex-wrap: wrap;
        background: var(--sun_gold, #ffd89d);
        border: 0;
        color: var(--grey, #312f2f);
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        margin: 0 auto;
    }

    .start__body__button:active {
        color: var(--white, #fff);
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: var(--sun_gold, #ffd89d);
        box-shadow: 0px 4px 30px 0px #ffe49a;
        margin: 0 auto;
    }

    .start-body-left {
        margin-left: 0px;
        z-index: 102;
        position: absolute;
        width: max-content;
        transform: translateX(0px);
        padding-left: 1em;
        padding-right: 1em;
        text-align: center;
        height: min-content;
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
    }

    .start-body-right {
        position: relative;
        width: -webkit-fill-available;
        top: -170px;
        left: 0px;
        height: 450px;
    }

    .start__page__body {
        display: flex;
        margin-top: 187px;
        height: 450px;
        width: 100%;
        justify-content: center;
    }

    .start__body_background {
        z-index: 101;
        top: 269px;
        position: absolute;
        display: block;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 43.94%, #000 100%, rgba(0, 0, 0, 0) 100%);
        width: 100vw;
        height: 572px;
    }

    .start-head-right {
        position: absolute;
        right: 0;
    }

    .start__burger__button {
        display: block;
        background: inherit;
        border: 0;
    }

    .start__burger__menu {
        z-index: 103;
        width: 260px;
        height: 126px;
        position: absolute;
        transition: 0.7s;
        -webkit-transition: right 0.3s ease;
        -moz-transition: right 0.3s ease;
        -ms-transition: right 0.3s ease;
        -o-transition: right 0.3s ease;
        text-align: left;
        margin-left: 40px;
        background: #111010;
        align-items: center;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        top: 0px;
        right: 0;
    }

    .start__burger__close {
        display: none;
    }

    .start__burger__text {
        background: none;
        border: 0;
        color: var(--lite-grey, #c5c4c4);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
    }

    .start__burger__x {
        position: relative;
        left: 207px;
        top: -104px;
        background-color: inherit;
        border: 0;
    }

    .start__img__x {
        width: 40px;
        font-size: 20px;
        color: white;
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
    }

    .start__burger__menu button:nth-of-type(1) {
        margin-left: 25px;
        margin-top: 20px;
    }

    .start__burger__menu button:nth-of-type(2) {
        margin-left: 25px;
    }

    .natali-logo__adapt {
        display: block;
        position: relative;
        top: -2em;
        flex-shrink: 0;
        right: 50px;
        width: 125vw;
    }
}

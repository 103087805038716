.page5__main__wrap {
    position: relative;
    background: #111010;
    height: max-content;
    padding-top: 100px;
    padding-bottom: 50px;
    margin-top: -1px;
}

.page5__head__text {
    white-space: nowrap;
    position: relative;
    width: 100vw;
    top: -19vw;
    margin: 0px auto;
    text-align: center;
    font-family: Poiret One;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.page5__head__flex {
    display: flex;
    justify-content: center;
}

.page5__text__white {
    color: var(--white, #fff);
}

.page5__text__yellow {
    color: var(--sun, #ffe49a);
    font-family: Poiret One;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.page5__text__bottom {
    color: var(--white, #dedbdb);
    font-family: Montserrat;
    font-size: 1.6vw;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
}

.page5__lines__main {
    display: block;
    margin: 0 auto;
}

.page5__br__adapt,
.page5__lines__adapt {
    display: none;
}

@media screen and (max-width: 767px) {
    .page5__lines__main {
        display: none;
    }

    .page5__lines__adapt {
        display: block;
        position: relative;
        margin: 0 auto;
    }

    .page5__head__flex {
        display: flex;
        justify-content: center;
    }

    .page5__main__wrap {
        position: relative;
        background: #111010;
        width: 100vw;
        padding: 33px 0 0;
        height: 32vw;
        padding-top: 0;
        margin-top: -1px;
    }

    .page5__head__text {
        position: relative;
        width: 100vw;
        top: 4vw;
        transform: translateX(-50%);
        left: 50%;
        margin: -19vw 0;
        color: var(--white, #fff);
        text-align: center;
        font-family: Poiret One;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .page5__text__yellow {
        color: var(--sun_gold, #ffd89d);
        font-family: Poiret One;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .page5__text__bottom {
        color: var(--white, #dedbdb);
        font-family: Poiret One;
        font-size: 8.333px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
    }

    .page5__br__adapt {
        display: block;
    }
}

@media screen and (max-width: 360px) {
    .page5__head__flex {
        display: block;
    }

    .page5__head__text {
        top: 1vw;
    }
}

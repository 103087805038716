.page2__natali__wrap {
    position: relative;
    background-image: url(../../assets/page2/Foto_fon_chess_2_3.webp);
    background-repeat: no-repeat;
    background-size: cover;
    height: 142em;
    padding: 1.25em 0 0 0;
    margin-top: -2px;
}

.page2__photo__natali {
    background-image: url(../../assets/page2/Foto_Natali_2.webp);
    background-size: 549px 866px;
    background-position: center;
    width: 34.31em;
    height: 866px;
    flex-shrink: 0;
    margin-top: 175px;
    float: left;
    margin-bottom: 118px;
    margin-left: 0;
}

.page2__float__photo {
    position: absolute;
    width: max-content;
    color: white;
    margin: -365px 0px 0 42vw;
    z-index: 3;
}

.page2__experience__flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 87px;
    flex-direction: row;
    text-align: center;
    padding: 0;
}

.page2__profile__wrap {
    padding: 0;
}

.page2__profile__head li:nth-of-type(2n + 1) {
    color: var(--white, #fff);
    font-family: Liana;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px;
    width: 97%;
    white-space: nowrap;
    height: max-content;
}

.page2__card__border {
    position: relative;
    top: 260px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 691px;
    flex-shrink: 0;
    border: 1px solid #e7c9a3;
}

.page2__profile__head {
    padding: 0;
    text-align: left;
}

.page2__profile__head li:nth-of-type(2n) {
    color: var(--sun, #ffe49a);
    font-family: Liana;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px;
    white-space: nowrap;
    height: max-content;
    line-height: normal;
}

#page2__expereanse {
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat;
    white-space: nowrap;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    text-align: left;
    margin: 68px 0 68px 0;
}

.page2__experience__old {
    color: var(--lite-gold, #d2b281);
    text-align: center;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.page2__experience__flex li {
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
    white-space: nowrap;
}

.page2__experience__flex li:nth-of-type(2) {
    margin: 0 5vw;
}

.page2__principles {
    justify-content: right;
    margin-right: 150px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    position: relative;
    color: var(--lite-gold, #d2b281);
    font-family: Poiret One;
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    clear: left;
    text-align: right;
    margin-left: 0;
    margin-bottom: 104px;
    left: 0;
}

.page2__principles__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: white;
    text-align: left;
}

.page2__principles__grid ul:nth-of-type(2n + 1) {
    display: flex;
    justify-content: right;
    margin-right: 150px;
    margin-top: 0;
}

.page2__principles__grid ul:nth-of-type(2n) {
    display: flex;
    justify-content: left;
    margin-left: 150px;
    margin-top: 0;
    padding: 0;
}

.page2__grid__li {
    width: 475px;
    color: var(--lite-grey, #c5c4c4);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.2%;
}

#page2__img__exellend {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    margin-right: 24px;
}

.page2__grid__li div:nth-of-type(1) {
    height: 135px;
    float: left;
}

.page2__principles__grid ul {
    margin-bottom: 74px;
}

.page2__map__p {
    margin: 0px;
}

.shrift__є__page2 {
    position: absolute;
    font-size: 24px;
    left: -3px;
    top: 34px;
    font-weight: 600;
}

#span__page2 {
    top: 46px;
}

.shrift__c__page2 {
    position: relative;
    width: 10px;
}

.page2__principles__adapt,
.page2__background__adapt,
.page2__br__adapt,
.page2__main_adapt {
    display: none;
}

@media screen and (max-width: 1298px) {
    .page2__profile__wrap {
        margin-top: -1em;
    }
    .page2__principles {
        text-align: left;
        left: 7vw;
        margin-right: 0;
    }

    .page2__principles__grid ul:nth-of-type(2n) {
        margin-left: 50px;
        margin-bottom: 74px;
    }

    .page2__principles__grid ul:nth-of-type(2n + 1) {
        margin-right: 50px;
        margin-bottom: 74px;
    }

    .page2__principles__grid ul {
        margin-bottom: 0;
    }

    .page2__profile__wrap {
        margin-top: -1em;
    }

    .page2__principles__grid ul {
        margin-bottom: 0;
    }

    .page2__natali__wrap {
        height: 2270px;
    }

    .page2__profile__head li:nth-of-type(2n) {
        white-space: nowrap;
        height: max-content;
        line-height: normal;
    }

    .page2__profile__head li:nth-of-type(2n + 1) {
        white-space: nowrap;
        height: max-content;
    }

    .shrift__є__page2 {
        position: absolute;
        font-size: 24px;
        left: -3px;
        top: 34px;
        font-weight: 600;
    }

    #span__page2 {
        top: 46px;
    }

    .shrift__c__page2 {
        position: relative;
        width: 10px;
    }

    #page2__expereanse {
        white-space: normal;
    }

    .page2__float__photo {
        margin: -365px 0 0 40vw;
        width: 46vw;
    }
}

@media screen and (max-width: 1128px) {
    .page2__profile__wrap {
        position: relative;
        width: 100%;
        margin-top: 1em;
    }

    #page2__expereanse {
        color: var(--lite-grey, #c5c4c4);
        font-family: Montserrat;
        white-space: normal;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        text-align: left;
        margin: 68px 0 68px 0;
    }

    .page2__profile__head li:nth-of-type(2n + 1) {
        color: var(--white, #fff);
        font-family: Liana;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.323px;
        width: 100%;
        white-space: nowrap;
        height: max-content;
    }

    .page2__float__photo {
        width: 63%;
        margin: -365px 0px 0 10vw;
    }

    .page2__experience__flex li:nth-of-type(2) {
        margin: 0px 7vw;
    }

    .page2__photo__natali {
        width: 50vw;
    }

    .shrift__є__page2 {
        top: 53px;
    }
}

@media screen and (max-width: 1100px) {
    .page2__principles__grid {
        display: block;
    }

    .page2__natali__wrap {
        height: 2480px;
    }

    .page2__principles__grid ul:nth-of-type(2n + 1) {
        justify-content: left;
        padding: 0;
        margin-left: 50px;
    }

    .page2__principles {
        display: block;
        text-align: left;
        margin-left: 0;
    }
    .page2__float__photo {
        width: 63%;
    }

    #page2__expereanse {
        white-space: normal;
    }

    .page2__principles__grid ul:nth-of-type(2n + 1) {
        display: flex;
        position: relative;
        justify-content: left;
        margin-right: 0;
        margin-top: 0;
        left: 0px;
        margin-bottom: 0;
    }

    .page2__principles__grid ul:nth-of-type(2n) {
        display: flex;
        justify-content: left;
        margin-left: 50px;
        margin-top: 0;
        padding: 0;
        margin-bottom: 0;
    }

    .page2__grid__li {
        width: 80vw;
    }
    #page2__expereanse {
        margin: 40px 0;
    }
}

@media screen and (max-width: 767px) {
    .page2__div__map:nth-of-type(2n) {
        margin-bottom: 18px;
    }

    .page2__natali__wrap {
        background-image: none;
        position: relative;
        height: 1140px;
        padding: 1.25em 0 0 0;
        width: 100%;
    }

    .page2__profile__wrap {
        position: relative;
        margin: 0 auto;
        left: 0;
        top: -455px;
        display: flex;
        width: 100vw;
        height: 111px;
        flex-direction: column;
        justify-content: center;
    }

    #span__page2 {
        top: 0;
    }

    .page2__main_adapt {
        display: flex;
        background-image: url(../../assets//page2/adapt/success.webp);
        background-repeat: no-repeat;
        width: 100vw;
        background-size: cover;
        height: 738.947px;
        margin: 66px 0 0 0;
    }

    .page2__photo__natali {
        position: absolute;
        background-repeat: no-repeat;
        width: 211px;
        height: 360px;
        margin-bottom: 0;
        flex-shrink: 0;
        background-image: url(../../assets/page2/Foto_Natali_2.webp);
        background-size: contain;
        float: right;
        top: -104px;
        left: 0;
        z-index: 0;
    }

    .page2__vertical__adapt {
        display: block;
        position: absolute;
        left: 155px;
        top: 72px;
        height: 370px;
        width: 100px;
        z-index: 2;
        background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, -1.5) -10%,
            rgba(1, 1, 1, 1) 55%,
            rgba(0, 0, 0, -0.5) 100%,
            rgba(0, 0, 0, 0) 100%
        );
    }

    .page2__profile__head {
        color: var(--white, #fff);
        text-align: center;
        font-family: Liana;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.323px;
    }

    .page2__profile__head li:nth-of-type(2n) {
        position: relative;
        color: var(--sun, #ffe49a);
        font-family: Liana;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.323px;
        height: 25px;
    }

    .shrift__є__page2 {
        transform: rotate(0);
        display: inline-block;
        position: relative;
        transform: translateY(1%);
        left: -9px;
        top: 0;
        font-size: 7px;
        font-weight: 600;
    }

    .shrift__c__page2 {
        position: relative;
        left: 1.5px;
        font-size: 23px;
    }

    .page2__profile__head li:nth-of-type(2n + 1) {
        position: relative;
        color: var(--white, #fff);
        text-align: center;
        font-family: Liana;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.323px;
        margin: 0 auto;
        white-space: nowrap;
    }

    .page2__float__photo {
        color: white;
        margin: 0;
    }

    #page2__expereanse {
        color: var(--lite-grey, #c5c4c4);
        text-align: center;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 125.2%;
        text-align: center;
        margin: 24px 0 28px 0;
    }

    .page2__br__adapt {
        display: flex;
    }

    .page2__experience__flex {
        display: flex;
        width: 100vw;
        height: 46.132px;
        flex-direction: row;
        justify-content: center;
        flex-shrink: 0;
    }

    .page2__experience__old {
        color: var(--lite-gold, #d2b281);
        text-align: center;
        font-family: Montserrat Bold;
        font-size: 33.936px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        height: max-content;
        height: 37px;
    }

    .page2__experience__flex li {
        color: #f1f0f0;
        font-family: Montserrat Medium;
        font-size: 12.726px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .page2__experience__flex li:nth-of-type(2) {
        margin: 0 24px;
    }

    .page2__border__adapt {
        width: 89%;
        height: 492px;
        flex-shrink: 0;
        border: 0.26px solid #e7c9a3;
        position: relative;
        left: 5vw;
        top: 20px;
        z-index: 999;
    }

    .page2__principles {
        position: relative;
        top: -745px;
        left: 1vw;
        display: flex;
        width: 260.156px;
        height: 23.958px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: var(--lite-gold, #d2b281);
        font-family: Poiret One;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        clear: left;
        text-align: left;
        margin-left: 20px;
        margin-bottom: 24px;
    }

    .page2__grid__li {
        position: relative;
        float: none;
        top: -745px;
        width: 90%;
        color: var(--lite-grey, #c5c4c4);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125.2%;
    }

    #page2__img__exellend {
        width: 15.63px;
        height: 15.63px;
        flex-shrink: 0;
        margin-right: 2px;
    }

    .page2__principles__adapt ul:nth-of-type(2n + 1) {
        margin: 0 50px 0 0;
        padding: 0;
    }

    .page2__principles__adapt {
        display: flex;
        color: white;
        text-align: left;
        flex-direction: column;
        margin-left: 20px;
    }

    .page2__principles__adapt ul:nth-of-type(2n) {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }

    .page2__principles__adapt ul {
        margin-bottom: 0px;
    }

    .page2__background__adapt {
        position: relative;
        margin-top: -245px;
        position: relative;
        top: 45px;
        z-index: 0;
        display: block;
        width: 100vw;
        height: 864px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 19%, #000 22.66%, #000 100%, rgba(0, 0, 0, 0) 100%);
    }

    .page2__grid__li div:nth-of-type(1) {
        height: 53px;
    }

    .page2__principles__grid,
    .page2__card__border {
        display: none;
    }

    .page2__principles__adapt {
        position: absolute;
        display: block;
        color: white;
        text-align: left;
    }

    .page2__principles__adapt ul {
        margin: 0 0 0 20px;
        padding: 0;
    }

    .page2__grid__li div:nth-of-type(1) {
        height: auto;
        float: left;
    }

    .page2__map__p {
        margin: 0px 0 0 20px;
    }
}

@media screen and (max-width: 620px) {
    .page2__natali__wrap {
        height: 1200px;
    }
}

@media screen and (max-width: 475px) {
    .page2__natali__wrap {
        height: 1250px;
    }
}

@media screen and (max-width: 430px) {
    .page2__natali__wrap {
        height: 1270px;
    }
}

@media screen and (max-width: 412px) {
    .page2__natali__wrap {
        height: 1250px;
    }
}

@media screen and (max-width: 400px) {
    .page2__natali__wrap {
        height: 1300px;
    }
}

@media screen and (max-width: 375px) {
    .page2__natali__wrap {
        height: 1280px;
    }
}

@media screen and (max-width: 360px) {
    .page2__background__adapt {
        margin-top: -287px;
    }

    .page2__natali__wrap {
        height: 1280px;
    }
}

@media screen and (max-width: 330px) {
    .page2__natali__wrap {
        height: 1350px;
    }
}

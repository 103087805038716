.page3__photo__wrap {
    display: flex;
    background-image: url(../../assets/page3/chessman.webp);
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    align-items: center;
    margin-top: -1px;
}

.page3__text {
    color: var(--white, #fff);
    width: 100%;
    text-align: center;
    font-family: Liana;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px;
}

.page3__border__adapt {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    width: 90%;
    height: 78%;
    flex-shrink: 0;
    border: 0.26px solid #e7c9a3;
}

.shrift__point {
    position: relative;
    top: -15px;
    left: -21px;
}

.page4__br__adapt {
    display: none;
}

@media screen and (max-width: 1270px) {
}

@media screen and (max-width: 767px) {
    .page3__photo__wrap {
        position: relative;
        margin-top: 0;
        display: flex;
        background-image: url(../../assets/page3/chessman.webp);
        background-position: bottom;
        justify-content: center;
        background-repeat: no-repeat;
        width: 100vw;
        height: 175.521px;
        flex-shrink: 0;
        align-items: center;
    }

    .shrift__point {
        position: relative;
        top: -4px;
        left: -6px;
    }

    .page3__text {
        display: block;
        color: var(--white, #fff);
        justify-content: center;
        font-family: Liana;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        align-items: center;
        text-align: center;
        font-family: Liana;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .page3__border__adapt {
        position: absolute;
        display: flex;
        width: 90%;
        height: 136px;
        flex-shrink: 0;
        border: 0.26px solid #e7c9a3;
    }

    .page4__br__adapt {
        display: block;
    }
}
